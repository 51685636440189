/* Dependencies */
// const { readFile } = require('fs').promises;

/* Components */
import Heap from 'heap';
import Maze from './Components/maze';

/* Variable */
const abs = Math.abs;
const SQRT2 = Math.SQRT2;

/* Function */
function backtrace(node) {
    const path = [[node.x, node.y]];
    while (node.parent) {
        node = node.parent;
        path.push([node.x, node.y]);
    }

    return path.reverse();
}

function Heuristic(dx, dy) {
    return dx + dy; // Manhattan Distance
}

/* Solver */
async function Search(startPos, endPos, maze, callback) {
    // Consider a square grid having many obstacles and we are given a starting cell and a target cell.
    // We want to reach the target cell (if possible) from the starting cell as quickly as possible.
    // Here A* Search Algorithm comes to the rescue.

    // -- A* Search (often used for the common pathfinding problem in applications such as video games) -- //
    // https://en.wikipedia.org/wiki/A*_search_algorithm

    // What A* Search Algorithm does is that at each step
    // it picks the node according to a value-‘f’ which is a parameter equal to the sum of two other parameters – ‘g’ and ‘h’.
    // At each step it picks the node/cell having the lowest ‘f’, and process that node/cell.

    // Create heap
    const openHeap = new Heap(function(A, B) { return A.f - B.f; });

    // Get the start and end node.
    const startNode = maze.getNode(startPos[1], startPos[0]);
    const endNode = maze.getNode(endPos[1], endPos[0]);

    // 'g' - the movement cost to move from the starting point to a given square on the grid,
    // following the path generated to get there.
    startNode.g = 0;
    startNode.f = 0;

    // push the start node into the heap

    let mileStone = [startNode];
    openHeap.push(startNode);
    startNode.opened = true;

    await sleep(1000);
    // while the heap is not empty
    while (!openHeap.empty()) {
        // pop the position of node that have a minimum 'f' value.
        const currentNode = openHeap.pop();
        currentNode.closed = true;

        // If reached the end of position, reverse the path to reconstruct and return it.
        if (currentNode === endNode) {
            return backtrace(endNode);
        }

        // get neighbors of the current node
        const neighbors = maze.getNeighbors(currentNode);
        for (const neighbor of neighbors) {
            if (neighbor.closed) {
                continue;
            }

            callback({ state: 'queue', data: openHeap });
            callback({ state: 'neighbors', data: neighbors });
            callback({ state: 'milestone', data: mileStone });
            callback({ state: 'path', data: backtrace(currentNode) });
            await sleep(1);

            // get the distance between current node and the neighbour and calculate the next g score
            const x = neighbor.x;
            const y = neighbor.y;
            const gScore = currentNode.g + ((x - currentNode.x === 0 || y - currentNode.y === 0) ? 1 : SQRT2);

            // check if the neighbor has not been inspected yet, or can be reached with smaller cost from the current node
            if (!neighbor.opened || gScore < neighbor.g) {
                neighbor.g = gScore;
                neighbor.h = neighbor.h || Heuristic(abs(x - endNode.x), abs(y - endNode.y));
                neighbor.f = neighbor.g + neighbor.h;
                neighbor.parent = currentNode;

                if (!neighbor.opened) {
                    openHeap.push(neighbor);
                    mileStone.push(neighbor);
                    neighbor.opened = true;
                } else {
                    // the neighbor can be reached with smaller cost.
                    // Since its f value has been updated, we have to update its position in the heap
                    openHeap.updateItem(neighbor);
                }
            }
        }
    }

    // return null if the path is not found
    return null;
}

/* Main */
export async function runMaze(input, callback) {
    // Read input file.
    // const input = await readFile('./maze.txt', 'utf-8');

    // Parse input file.
    const inputSplitted = input.split(/[\r\n]+/);
    const matrix = [];
    let startPos;
    let endPos;

    for (const x in inputSplitted) {
        matrix[x] = [];

        for (const y in inputSplitted[x]) {
            const char = inputSplitted[x][y];

            if (char === 'S') {
                startPos = [x, y];
            } else if (char === 'E') {
                endPos = [x, y];
            }

            matrix[x][y] = char === 'X' ? 0 : 1;
        }
    }

    callback({ state: 'maze', data: matrix });
    callback({ state: 'startPoint', data: startPos });
    callback({ state: 'exitPoint', data: endPos });

    // Create maze and solve it.
    const startTime = Date.now();
    const results = await Search(startPos, endPos, new Maze(matrix), callback);
    const endTime = Date.now();

    // If the results is null, algorithm can't find a path for this maze.
    if (!results) {
        console.log('No path found.');
        callback({ state: 'result', data: "No path found."});
        return;
    }

    // Build the maze with the path.
    const maze = matrix.map(row => row.map(cell => cell === 1 ? ' ' : 'X'));
    results.forEach(([y, x]) => maze[x][y] = '•');
    maze[startPos[0]][startPos[1]] = 'S';
    maze[endPos[0]][endPos[1]] = 'E';
    maze.forEach(row => console.log(row.join('')));

    // Output.
    console.log('Total solving time (Only algorithm, Parsing and maze building not incl.):', endTime - startTime, 'ms');
    console.log('Total steps (Start Pos doesn\'t count):', results.length - 1);
    callback({ state: 'result', data: "Shortest Path is " + (results.length - 1) });

    await sleep(500)
    // callback({ state: 'finish', data: true });
    await sleep(1200)
    callback({ state: 'cleanUp', data: true });
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// runMaze();