/* Function */
function Node(x, y, walkable) {
    this.x = x;
    this.y = y;
    this.walkable = (walkable === undefined ? true : walkable);
}

/* Classes */

// A class that contains a nodes in x, y-axis to simulate maze structure.
class Maze {
    constructor(matrix) {
        // The number of columns of the grid.
        this.width = matrix[0].length;

        // The number of rows of the grid.
        this.height = matrix.length;

        // Array of nodes.
        this.nodes = this.createNodes(matrix);
    }

    // Create nodes from the matrix.
    createNodes(matrix) {
        const nodes = new Array(this.height);
        for (let i = 0; i < this.height; ++i) {
            nodes[i] = new Array(this.width);
            for (let j = 0; j < this.width; ++j) {
                nodes[i][j] = new Node(j, i);
            }
        }

        if (!matrix) {
            return nodes;
        }

        for (let i = 0; i < this.height; i++) {
            for (let j = 0; j < this.width; j++) {
                if (matrix[i][j] === 0) {
                    nodes[i][j].walkable = false;
                }
            }
        }

        return nodes;
    }

    // Get node from specified x, y
    getNode(x, y) {
        return this.nodes[y][x];
    }

    // Determine whether the position is inside the maze.
    isCoordsInside(x, y) {
        return (x >= 0 && x < this.width) && (y >= 0 && y < this.height);
    }

    // Determine whether the node at the given position is walkable.
    isCoordsWalkable(x, y) {
        return this.isCoordsInside(x, y) && this.nodes[y][x].walkable;
    }

    // Get the neighbors of the specified node.
    getNeighbors(node) {
        const x = node.x;
        const y = node.y;
        const neighbors = [];

        // North
        if (this.isCoordsWalkable(x, y - 1)) {
            neighbors.push(this.nodes[y - 1][x]);
        }

        // East
        if (this.isCoordsWalkable(x + 1, y)) {
            neighbors.push(this.nodes[y][x + 1]);
        }

        // South
        if (this.isCoordsWalkable(x, y + 1)) {
            neighbors.push(this.nodes[y + 1][x]);
        }

        // West
        if (this.isCoordsWalkable(x - 1, y)) {
            neighbors.push(this.nodes[y][x - 1]);
        }

        return neighbors;
    }
}

/* Exports */
export default Maze;