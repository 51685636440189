// Dependencies
import React from 'react'
import { motion } from 'framer-motion'

class Maze extends React.Component {

	
	constructor(props) {
		super(props)
		if(!props.maze) throw new Error('maze is not defined')
	}


	render() {
		const {
			maze,
			startPoint,
			exitPoint,

			milestone,
			neighbors,
			path,
			queue,
			finish,
			cleanUp
		} = this.props;

		let tmpMaze = maze.map(row => row.map(cell => cell === 1 ? ' ' : 'X'));
		// console.log(path)
		if(path) {
			path.forEach(([x, y]) => {
				
				tmpMaze[y][x] = '•';

				// console.log(x, y, tmpMaze[x][y]);
			});
		}

		// console.log(startPoint,
		// 	exitPoint,)

		let queueMap = {};
		if(queue) {
			// console.log(queue)
			queue.nodes.forEach((item, index) => {
				// console.log(item)
				queueMap[item.y + '-' + item.x] = item;
			})
		}

		// console.log(milestone)

		let neighborsMap = {};
		if(neighbors) {
			neighbors.forEach((item, index) => {
				neighborsMap[item.y + '-' + item.x] = true
			});
		}

		let mileMap = {};
		if(milestone) {
			milestone.forEach((item, index) => {
				mileMap[item.y + '-' + item.x] = item.g
			});
		}
		// console.log(mileMap)

		return <motion.div className={'maze-map finish'
				+ (cleanUp ? ' cleanUp' : '')
				+ (finish ? ' finish' : '')
			}
			key={'maze-map'}
			initial={{
				scale: 0.5,
				opacity: 0
			}}
			animate={{
				scale: 1,
				opacity: 1
			}}
			exit={{
				scale: 0,
				opacity: 0
			}}
			transition={{
				duration: 0.75,
				delay: 0.25
			}}
		>
			<motion.div className='sub'>
				{
					maze.map((row, i) => {
						return <div
							key={'row-' + i}
							className='row'
						>
							{
								row.map((cell, j) => {
									return <div
										key={'column-' + j}
										className={
											'column' + (cell === 0 ? ' wall' : '')
											+ (startPoint[0] == i && startPoint[1] == j ? ' start' : '')
											+ (exitPoint[0] == i && exitPoint[1] == j ? ' exit' : '')
											+ (mileMap[i + '-' + j] ? ' walked' : '')
											+ (neighborsMap[i + '-' + j] ? ' neighbors' : '')
											+ (tmpMaze[i][j] === '•' ? ' path' : '')
											+ (queueMap[i + '-' + j] ? ' queue' : '')
										}
									>
										{(startPoint[0] === i && startPoint[1] === j ? '0' : '')}
										{(mileMap[i + '-' + j] ? mileMap[i + '-' + j] : '')}
									</div>
								})
							}

						</div>
					})
				}
			</motion.div>
        </motion.div>
	}
}

export default Maze;